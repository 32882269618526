@import "minima";


//////////////// GLOBAL ////////////////////////

.minimum-height {
  min-height: 50vh !important;
}

main a, main a:hover, main a:visited {
  color: inherit;
}

footer a, footer a:hover, footer a:visited {
  text-decoration: none;
  color: inherit;
}

.wrapper {
  max-width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0;
  padding-left: 0;
  height: 100% !important;
}

main {
  background-color: rgba(255, 255, 255, 1);
}

main, footer, .seo-footer {
  font-family: 'Arial';
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
}

.page-content {
  padding: 0;
  
}

h2, h3 {
  font-weight: 300 !important;
}

a:hover {
  text-decoration: none;
}

body {
  background-color: rgba(255, 255, 255, 1);
}

.anchors {
  display: block;
  position: relative;
  top: -20vh;
  visibility: hidden;
}

input[type=file]::file-selector-button {
  margin-right: 8px;
  border: 1px solid;
  border-radius: 0.375rem;
  background-color: transparent;
  padding: 8px 12px;
  cursor: pointer;
}

input[type=file]::file-selector-button:hover {
  background-color: #212529;
  color: #FFF;
}

////////////////////////////////////////////////

//////////////// FONTS ////////////////////////


  
      main, footer, .breadcrumb-item {
          font-family: 'Lato', '', 'Arial !important';
      }
    


////////////////////////////////////////////////

//////////////// FIL D'ARIANE ////////////////////////


.fil-ariane {
  font-size: 0.8em !important;
  font-family: 'Arial';
}


//////////////// HEADER ////////////////////////

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-toggle{
  line-height: 1.2em;
}

a.dropdown-item:active {
  background-color: inherit !important;
}

a.dropdown-item:hover {
  background-color: rgba(17, 31, 19, 0.71) !important;
}

a.nav-link {
  font-size: 1.1em;
}
.navbar-brand {
  width: 6vw;
  height: 6vh;
}

.navbar {
  min-height: 6vh;
  background-color: rgba(220, 52, 60, 1) !important;
}

#navigation-bar-hide {
  opacity: 0;
}

.button_header {
  background-color: rgba(17, 31, 19, 1);
}

.button_header:hover {
  background-color: rgba(17, 31, 19, 0.71);
}

.logo_nav {
  object-fit: contain !important;
  height: 6vh;
}

.button-header-telephone {
  white-space: nowrap;
}

// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {

  .navbar-brand {
    width: 25vw;
  }

  .logo_nav {
    object-fit: contain !important;

  }

}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .navbar-brand {
    width: 25vw;
  }

  .logo_nav {
    object-fit: contain !important;
  }

}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-brand {
    width: 25vw;
  }

  .logo_nav {
    object-fit: contain !important;
  }

}

////////////////////////////////////////////////


/////////// HOMEPAGE SCREEN BLOC ///////////////

.wallpaperHomePage{
  width: 100%;
  height: 100%;
  padding-bottom: 23%;
  background-size: cover;
  background-repeat: no-repeat;
  filter: brightness(80%);
}

.fondDegradeLogo {
  object-fit: contain !important;
}

.sliderLogoContainer {
  width: 35vw !important;
}

.bloc-slider-homepage {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -70%);
  opacity: 1;
  z-index: 99;
  width: 80%;
}

a.button_homepageScreen {
  z-index: 99;
  padding: 0.9em;
  background-color: rgba(17, 31, 19, 1);
  border-radius: 50px;
  cursor: pointer;
  border: none;
  text-align: center;
  text-decoration: none;
}

a.button_homepageScreen:hover {
  background-color: rgba(17, 31, 19, 0.71);
}

.titleText {
  letter-spacing: 0.2em;
  font-weight: 300;
  line-height: 1.5em;
  z-index: 99;
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {

  .fondDegradeLogo {
    object-fit: contain !important;
  }


  .sliderLogoContainer {
    width: 55vw !important;
  }


  a.button_homepageScreen {
    padding: 0.6em;
  }

}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {


  .fondDegradeLogo {
    object-fit: contain !important;
  }

  .sliderLogoContainer {
    width: 55vw !important;
  }


  a.button_homepageScreen {
    padding: 0.6em;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {

  .fondDegradeLogo {
    object-fit: contain !important;
  }

  .sliderLogoContainer {
    width: 55vw !important;
  }

  a.button_homepageScreen {
    padding: 0.6em;
  }
}

////////////////////////////////////////////////



//////////////// PHOTO TEXT BLOC ////////////////////////

.button-contact {
  padding: 0.7em;
  background-color: rgba(17, 31, 19, 1);
  border-radius: 50px;
  cursor: pointer;
  border: none;
  text-decoration: none;
}

.button-contact:hover {
  background-color: rgba(17, 31, 19, 0.71);
}

.photoTitleText {
  text-align: center;
  padding: 3%;
  margin-bottom: 0;
  background-color: rgba(255, 235, 238, 1);
}


//  small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 991px) {

  .titleText {
    padding: 5%;
  }
  #photoTextLottie {
    width: 100% !important;
    margin: auto;
  }
  .button-contact {
    padding: 0.6em;
  }
  .photoTitleText {
    padding: 5%;
  }

}


////////////////////////////////////////////////



//////////////// GALLERY BLOC //////////////////

.thumbnail-modal {
  cursor: pointer;
  padding: 0 !important;
}

////////////////////////////////////////////////



//////////////// MAPS BLOC  ////////////////////

.icon-maps {
  width: 20%;
}

.map-content > iframe {
  width: 100%;
}

// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {

  .icon-maps {
    width: 10%;
    margin: 0 auto !important;
  }

  .informations {
    margin: 0 auto !important;
  }

  .maps {
    height: 50vh;
  }

}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {

  .icon-maps {
    width: 10%;
    margin: 0 auto !important;
  }

  .informations {
    margin: 0 auto !important;
  }

  .maps {
    height: 50vh;
  }
}


// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) and (max-width: 1199px) {
  .map-content > iframe {
    height: 100%;
  }
}

// X-Large devices (large desktops, between 1200px and 1399px)
@media (min-width: 1200px) and (max-width: 1399px) {
  .map-content > iframe {
    height: 100%;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .map-content > iframe {
    height: 100%;
  }
}

////////////////////////////////////////////////

.homepage-double-screen {
  height: 100vh !important;
  background-color: rgba(255, 255, 255, 1)
}

.logoContainer {
  width: 250px;
  height: 150px;
}

.logoDoubleScreen {
  object-fit: contain !important;
}

.halfScreen {
  width: 50% !important;
}

.halfScreen1 {
  border-right: 1px solid white;
}

a.button_homepageDoubleScreen {
  z-index: 99;
  padding: 0.9em;
  background-color: rgba(17, 31, 19, 1);
  border-radius: 50px;
  cursor: pointer;
  border: none;
  text-align: center;
  text-decoration: none;
}

a.button_homepageDoubleScreen:hover {
  background-color: rgba(17, 31, 19, 0.71);
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .logoContainer {
    width: 150px;
    height: 100px;
  }

  .halfScreen {
    width: 100% !important;
    height: 50vh;
  }
  a.button_homepageDoubleScreen {
    padding: 0.6em;
  }

  .halfScreen2 {
    border-top: 1px solid white;
  }

  .halfScreen1 {
    border-right: none;
  }


}


@media (min-width: 576px) and (max-width: 767px) {
  .halfScreen {
    width: 100% !important;
    height: 50vh;
  }
  .logoContainer {
    width: 150px;
    height: 100px;
  }
  a.button_homepageDoubleScreen {
    padding: 0.6em;
  }
  .halfScreen2 {
    border-top: 1px solid white;
  }

  .halfScreen1 {
    border-right: none;
  }

}


// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .halfScreen {
    width: 100% !important;
    height: 50vh;
  }
  .logoContainer {
    width: 150px;
    height: 100px;
  }
  a.button_homepageDoubleScreen {
    padding: 0.6em;
  }
  .halfScreen2 {
    border-top: 1px solid white;
  }

  .halfScreen1 {
    border-right: none;
  }
}

///////////////////////////////////////////



///////////// SLIDER HOMEPAGE BLOC ////////

#myCarousel {
  height: 100% !important;
  width: 100% !important;
}

.carousel-homepage .carousel-inner-homepage, .carousel-item-homepage {
  width: 100%;
  height: 100%;
}

.carousel-item-homepage img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: 50% 50%;
  filter: brightness(70%);
}


.carousel-inner-homepage .carousel-item-homepage > img {
  -webkit-animation: thing 20s;
  -o-animation: thing 20s;
  animation: thing 20s;
}

.lottie-scroll {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 20px;
  left: 47%;
  z-index: 1000
}

// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 992px) {
  .lottie-scroll {
    width: 60px;
    height: 60px;
    left: 40%;
  }
}



@keyframes thing {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.5, 1.5);
  }
}


// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .carousel-item-homepage img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .carousel-item-homepage img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

////////////////////////////////////////////////



//////////////// OWL CAROUSEL BLOC /////////////



////////////////////////////////////////////////



///////////// CONTACT FORM BLOC ////////////////

form {
  width: 70%;
}

@media only screen and (max-width: 800px) {
  form {
    width: 90%;
  }
}

////////////////////////////////////////////////



///////// PRODUCT CARDS BLOC /////////////////

.cards-container {
  display: flex;
  margin: 50px 0px;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}

.card-container {
  font-size: 1.1rem;
  width: 350px;
  height: 350px;
  background-size: cover;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-radius: 5px;
  margin: 25px 35px;
}

.card-location-container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;
  word-wrap: break-word;
  margin-top: -15px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.card-location {
  background-color: rgba(220, 52, 60, 1);
  max-width: 350px;
  padding: 0 10px;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.card-location p {
  margin: 7px;
}

.card-price {
  background-color: rgba(255, 235, 238, 1);
  position: absolute;
  bottom: 90px;
  box-sizing: border-box;
  left: -30px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.card-price p {
  font-weight: bold;
  margin: 5px 15px;
}

.card-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 72px;
  text-align: center;
  box-sizing: border-box;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-title p {
  font-weight: bold;
  margin: 12px;
}

////////////////////////////////////////////////


///////// CAROUSEL PARTENAIRES /////////////////

.owl-stage {
  display: flex;
  align-items: center;
}

.owl-nav {
  margin-top: 0 !important;
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background: none !important;
}

.owl-prev {
  width: 15px;
  height: 100px;
  position: absolute;
  top: 35%;
  left: -10px;
  display: block !important;
}

.owl-next {
  width: 15px;
  height: 100px;
  position: absolute;
  top: 35%;
  right: 6px;
  display: block !important;
}


////////////////////////////////////////////////


///////// PRODUCT LANDING BLOC /////////////////

// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .productMainContainer {
    width: 80% !important;
    margin-top: 20vh;
  }

  .productInfoContainer {
    padding-top: 5%;
    padding-right: 0!important;
    padding-left: 0 !important;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .productMainContainer {
    width: 80% !important;
    margin-top: 20vh;

  }

  .productInfoContainer {
    padding-top: 5%;
    padding-right: 0!important;
    padding-left: 0 !important;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .productMainContainer {
    width: 80% !important;
    margin-top: 20vh;

  }

  .productInfoContainer {
    padding-top: 5%;
    padding-right: 0!important;
    padding-left: 0 !important;
  }
}

// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) and (max-width: 1199px) {
  .productMainContainer {
    width: 80% !important;
    margin-top: 20vh;
  }

  .productInfoContainer {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .productContactCard {
    width: 75%;
  }
}

// X-Large devices (large desktops, between 1200px and 1399px)
@media (min-width: 1200px) and (max-width: 1399px) {
  .productMainContainer {
    width: 80% !important;
    margin-top: 20vh;

  }

  .productInfoContainer {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .productContactCard {
    width: 75%;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .productMainContainer {
    width: 80% !important;
    margin-top: 20vh;

  }

  .productInfoContainer {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .productContactCard {
    width: 75%;
  }
}

////////////////////////////////////////////////



///////// BANDEAU IMAGE EN-TETE PAGE ///////////

.bandeau {
  min-height: 46vh;
  background-color: rgba(220, 52, 60, 1);
}

// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .bandeau {
    min-height: 45vh;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .bandeau {
    min-height: 45vh;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .bandeau {
    min-height: 45vh;
  }
}

////////////////////////////////////////////////



//////////// SERVICES LIST BLOC /////////////

.img-services-list {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 99;
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .img-services-list {
    min-height: 30vh;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .img-services-list {
    min-height: 30vh;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .img-services-list {
    min-height: 30vh;
  }
}

////////////////////////////////////////////////

//////////// AVIS GOOGLE BLOC /////////////

.avatar-google-reviews {
    width: 10% !important;
}

.owl-prev {
  margin: 0 10px;
}

.owl-prev > span {
  font-size: 2em;
}

.owl-next > span {
  font-size: 2em;
}

.star {
  color: rgb(252, 191, 2);
}

//////////// SERVICES GALLERY BLOC /////////////

a.link-services {
  text-decoration: none;
}

.title-services {
  color: black;
  background-color: white;
  padding: 6%;
  border-radius: 10px;
  font-size: 1.1em;
}

.services_gallery_card {
  border: 1px solid lightgray;
  border: 1px solid lightgray;
  margin: 12px;
  text-decoration: none;
  overflow: hidden;
  min-height: 25vh;
}

.services_icon_card {
  width: 1.6em;
}

#servicesButton {
  width: 100%;
}

.servicesGallery-card-header {
  background-color: rgba(17, 31, 19, 1) !important;
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .services_gallery_card {
    padding: 3em 3em !important;
  }
  .services_icon_card {
    width: 3em;
    height: 3em;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .services_gallery_card {
    padding: 3em 3em !important;
  }
  .services_icon_card {
    width: 3em;
    height: 3em;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .services_gallery_card {
    width: 40%;
  }
}

////////////////////////////////////////////////



////////////// MENTIONS LEGALES ////////////////

.containersMentionLegales {
  margin: 5% auto;
}

////////////////////////////////////////////////



///////////////////// COOKIES //////////////////

.termsfeed-com---palette-light .cc-nb-okagree,
.termsfeed-com---palette-light .cc-nb-reject {
  background-color: black !important;
  border-radius: 50px;
  font-weight: lighter !important;
}

.termsfeed-com---palette-light .cc-nb-changep {
  border-radius: 50px;
  font-weight: lighter !important;
}

#cc-nb-title {
  font-weight: lighter;
}

#termsfeed-com---nb {
  border-radius: 50px;
}

#cc-nb-text {
  font-size: 12px;
}

.termsfeed-com---nb .cc-nb-main-container {
  padding: 2rem !important;
}


// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) {
  .termsfeed-com---nb-simple {
    max-width: 34% !important;
  }
}





////////////////////////////////////////////////



//////////////// CTA BLOC //////////////////////


.cta_banner {
  min-height: 30vh;
  padding-top: 3em;
}


// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) and (max-width: 1199px) {
  .cta_banner {
    background-attachment: fixed;
  }
}

// X-Large devices (large desktops, between 1200px and 1399px)
@media (min-width: 1200px) and (max-width: 1399px) {
  .cta_banner {
    background-attachment: fixed;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .cta_banner {
    background-attachment: fixed;
  }
}



////////////////////////////////////////////////



/////// ACCOMPAGNEMENT TEXT BLOC //////////////

.title-accompagnement {
  text-transform: uppercase;
}


// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) and (max-width: 1199px) {
  .accompagnement_text {
    background-attachment: fixed;
  }
}

// X-Large devices (large desktops, between 1200px and 1399px)
@media (min-width: 1200px) and (max-width: 1399px) {
  .accompagnement_text {
    background-attachment: fixed;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .accompagnement_text {
    background-attachment: fixed;
  }
}



////////////////////////////////////////////////



////////// DOUBLE IMAGES TEXT BLOC  ////////////

.double-images {
  background-color: rgba(255, 255, 255, 1);
  padding-top: 5%;
  padding-bottom: 5%;
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {

  .double-images {
  padding-top: 0;
  padding-bottom: 0;
  }

  .text-double-images {
    order: 2;
    padding: 10% !important;
  }

  .first-image {
    order: 1;
  }

  .second-image {
    order: 3;
  }

}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {

  .double-images {
    padding-top: 0;
    padding-bottom: 0;
  }

  .text-double-images {
    order: 2;
    padding: 10% !important;
  }

  .first-image {
    order: 1;
  }

  .second-image {
    order: 3;
  }
}

////////////////////////////////////////////////

// CAROUSEL SEO //


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .carousel-seo-card, .carousel-seo-image {
    height: 28vh;
  }
  .priority-carousel > .owl-dots {
    display: none;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .carousel-seo-card, .carousel-seo-image {
    height: 28vh;
  }
  .priority-carousel > .owl-dots {
    display: none;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .carousel-seo-card, .carousel-seo-image {
    height: 22vh;
  }
  .priority-carousel > .owl-dots {
    display: none;
  }
}

// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) and (max-width: 1199px) {
  .carousel-seo-card, .carousel-seo-image {
    height: 36vh !important;
  }
}

// X-Large devices (large desktops, between 1200px and 1399px)
@media (min-width: 1200px) and (max-width: 1399px) {
  .carousel-seo-card, .carousel-seo-image {
    height: 36vh !important;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .carousel-seo-card, .carousel-seo-image {
    height: 36vh !important;
  }
}

.obftrucs:hover, #open_preferences_center:hover {
  text-decoration: underline;
  cursor: pointer;
}

.footer-infos:hover {
  text-decoration: underline;
}

.logo-footer {
  width: 35vw;
}

////////////////////////////////////////////////
///////////////// BLOC INSTAGRAM MIROIR ////////

.instagram-media-rendered {
  background: white;
  min-height: 62vh;
  max-width: 40vw;
  width: calc(100% - 2px);
  border-radius: 10px;
  display: block;
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .instagram-media-rendered {
    max-width: 100%;
    min-height: 45vh !important;
    margin-top: 20px;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .instagram-media-rendered {
    max-width: 100%;
    min-height: 45vh !important;
    margin-top: 20px;

  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .instagram-media-rendered {
    max-width: 100%;
    min-height: 45vh !important;
    margin-top: 20px;

  }
}

// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) and (max-width: 1199px) {
  .instagram-media-rendered {
    max-width: 100%;
  }
}

// X-Large devices (large desktops, between 1200px and 1399px)
@media (min-width: 1200px) and (max-width: 1399px) {
  .instagram-media-rendered {
    max-width: 100%;

  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1700px) {
  .instagram-media-rendered {
    min-height: 52vh;
    max-width: 30vw;
  }
}


///////////////// BLOC PICTOCARD ///////////////

.svg-picto-card > svg {
  width: 20%;
  height: 20%;
  margin: 0 auto;
  color: rgba(17, 31, 19, 1);
}

// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) and (max-width: 1199px) {
  .picto_card {
    background-attachment: fixed;
  }
}

// X-Large devices (large desktops, between 1200px and 1399px)
@media (min-width: 1200px) and (max-width: 1399px) {
  .picto_card {
    background-attachment: fixed;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .picto_card {
    background-attachment: fixed;
  }
}




////////////////////////////////////////////////



///////////////// PRODUCT CARDS ///////////////

// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .productCard {
    width: 300px !important;
    height: 300px !important;
  }
}

////////////////////////////////////////////////



///////////////// SEO MAIN /////////////////////

.logo_card {
  width: 50% !important;
}

.button_seo {
  padding: 0.4em;
  background-color: rgba(17, 31, 19, 1);
  border-radius: 50px;
  cursor: pointer;
  border: none;
  text-align: center;
  text-decoration: none;
}

.button_seo:hover {
  background-color: rgba(17, 31, 19, 0.71);
}

.img-seo {
  max-height: 50vh
}

.seo-frequently-card {
  max-width: 800px;
  background-color: rgba(255, 255, 255, 1) !important;
}

////////////////////////////////////////////////

//////////////// ACT LOC LINKS BLOC ////////////////////////

.black-screen {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.29;
  z-index: 1;
}



////////////////////////////////////////////////
///////////////// NEWS POSTS /////////////////////

.small-font {
  font-size: 12px;
}

.custom-image {
  height: 300px; /* Remplacez la valeur par celle que vous souhaitez */
  object-fit: cover;
}

.image-latestNews {
  object-fit: cover;
  object-position: center;
}

.owl-items-news > img {
  border-radius: 5px !important;
}

.owl-carousel-news > .owl-stage-outer > .owl-stage {
  display: block;
}

.owl-dots{
  position: absolute;
  bottom:10px;
  left:25%;
  right:25%;
}

.latestNews-card {
  width: 60%;
}





// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .latestNews-card {
    width: 100%;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .latestNews-card {
    width: 100%;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .latestNews-card {
    width: 100%;
  }
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .otherNews-card {
    margin: 0 auto;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .otherNews-card {
    margin: 0 auto;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .otherNews-card {
    margin: 0 auto;
  }
}

//////////////// VIDEOHOMEPAGE BLOC /////////////////////

.video-background-holder {
  position: relative;
  background-color: black;
  height: 100vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

.video-background-holder video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.video-background-content {
  position: relative;
  z-index: 2;
}

.video-background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.2;
  z-index: 1;
}

.reservation-button:hover {
  background-color: white;
}

.image-video-logo {
  width: 40%;
}

.videoTitle {
  width: 50% !important;
}

// small devices (smartphones)
@media (min-width: 320px) and (max-width: 991px) {
  .image-video-logo {
    width: 70%;
  }
  .videoTitle {
    width: 90% !important;
  }
}


////////////////////////////////////////////////

///////////////// VIDEO PLAYER BLOC /////////////////////
@media screen and (max-width: 991px) {
  .player-video {
    width: 100%;
    height: auto;
    max-height: 40vh;
  }
  .player-video-container {
    height: 40vh;
  }
}

@media screen and (min-width: 991px) {
  .player-video {
    width: 75%;
    height: auto;
    max-height: 70vh;
  }
  .player-video-container {
    height: 70vh;
  }
}

////////////////////////////////////////////////

///////////////// BLOC VIDEO TEXT BLOC  /////////////////////
.portrait-video-container {
  height: 50vh;
  overflow: hidden;
}

.portrait-video {
  width: 100%;
  height: auto;
  max-height: 50vh;
}
////////////////////////////////////////////////

///////////////// BLOC OFFCANVASIFRAME  ////////
.reservation-button {
  display: none;
  position:fixed;
  bottom: 20px;
  right: 20px;
  z-index: 200;
}

.iframe-card {
  width: 400px !important;
  height: 600px;
  bottom: 20px;
  right: 20px
}
///////////////////////////////////////////////
///////////////// BLOC BEFOREAFTERGALLERY  ////////

.no-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.before-after-images-column {
  max-height: 50vh
}

.before-after-slider-button {
  height: 100%
}

.image-before-after-container {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.before-image-gallery, .after-image-gallery {
  z-index: 2;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.after-image-gallery {
  z-index: 1;
}

.slider-before-after-images {
  height: 50px;
  width: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  border: 3px solid white;
  border-radius: 1000px;
  box-shadow: 0 0 12px rgba(51,51,51,.5);
  z-index: 40;
  background: white;
  cursor: pointer;
  touch-action: none;
}

.slider-before-after-images:before {
  content: " ";
  display: block;
  background: white;
  position: absolute;
  z-index: 30;
  width: 5px;
  height: 900px;
  left: 50%;
  margin-left: -2px;
  bottom: 50%;
  margin-bottom: 22px;
}

.slider-before-after-images:after {
  content: " ";
  display: block;
  background: white;
  position: absolute;
  z-index: 30;
  width: 5px;
  height: 900px;
  left: 50%;
  margin-left: -2px;
  top: 50%;
  margin-top: 22px;
}

///////////////////////////////////////////////
/////////////// BLOC SLIDERWITHFEATURES ////////////

.fixed-caption {
  position: absolute;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  padding: 20px !important;
}

.slider-with-features-carousel-logo {
  height: 15vh !important;
}

@media (min-width: 320px) and (max-width: 991px) {
  .slider-with-features-carousel-button {
    display: none;
  }
  .slider-with-features-carousel-logo {
    height: 10vh !important;
  }
  .bd-placeholder-img {
    height: 60vh;
  }
}

.bd-placeholder-img {
  height: 78vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

////////////////////////////////////////////////

////////// REDIRECTSPICTURESCARDS BLOC //////////
.redirectsCards-div {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.redirects-title-background {
  background-color: rgba(255, 255, 255, 0.6);
}
//////////////////////////////////////////////////////////
/////////// WEBSITEPAGETITLEPICTURE BLOC //////////


.img-background-title {
  height: 60vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.title-picture-line {
  border-top: 6px solid;
  opacity: 1
}

///////////////////// ANIMATIONS /////////////////////////
//////////////////////////////////////////////////////////


////////////// ANIMATIONS JS ///////////////////

.animated-border-button {
  position: relative;
  display: inline-block;
  background-color: rgba(220, 52, 60, 1);
}

.animated-border-button:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: rgba(255, 255, 255, 1);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.animated-border-button:hover:after {
  visibility: visible;
  transform: scaleX(1);
}

////////////////////////////////////////////////



.item > img {
  border-radius: 10px;
}


.background-image-div-opacity {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

//////////////// GROW EFFECT ///////////////////

.grow {
  transition: all .2s ease-in-out;
}

.grow:hover {
  transform: translate(2%, -3%);
}

////////////////////////////////////////////////

//////////////// ZOOM EFFECT ///////////////////
.zoom {
  transition: transform .2s;
}

.zoom:hover {
  transform: scale(1.02);
}
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////



//////////// Squelette media queries ///////////

// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {

}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {

}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {

}

// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) and (max-width: 1199px) {

}

// X-Large devices (large desktops, between 1200px and 1399px)
@media (min-width: 1200px) and (max-width: 1399px) {

}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {

}

////////////////////////////////////////////////
